// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   SERVER_URL: 'https://dev.autonise.com/api/',
//   SOCKET_URL: 'wss://dev.autonise.com',
//   RAZORPAY_ID: 'rzp_test_ThcPMW9w861Uwv',
//   LOG_USER: false
// };

export const environment = {
  production: false,
  SERVER_URL: 'http://localhost:8000/api/',
  SOCKET_URL: 'ws://localhost:8000',
  RAZORPAY_ID: 'rzp_test_ThcPMW9w861Uwv',
  LOG_USER: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
