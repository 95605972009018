import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { throwError, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AttendanceService } from './attendance.service';
import { NotificationService } from './notification.service';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  loggedInSubject: Subject<boolean> = new Subject();

  user = null;
  loggedIn = null;

  constructor(
    private http: HttpClient, private router: Router,
    private attendanceService: AttendanceService,
    private notification: NotificationService,
    private platform: Platform
  ) {
    window.addEventListener('storage', (event) => {
      if (event.storageArea === localStorage) {
        const token = localStorage.getItem('token');
        if (token === 'null') {
          this.router.navigate(['/logout']);
        }
      }
    }, false);
  }

  isLoggedIn() {
    const email = localStorage.getItem('email');
    const token = localStorage.getItem('token');
    return email !== 'null' && email !== null && token !== 'null' && token !== null;
  }

  checkLinkedin(linkedinToken, redirectURL) {
    let hostname = window.location.hostname;
    if (!this.platform.is('capacitor')) {
      if (hostname === 'localhost') {
        hostname = hostname + ':8100';
      }
    }
    this.http.post(environment.SERVER_URL + 'users/linkedinLogin',
      {
        token: linkedinToken,
        redirect_uri: window.location.protocol + '//' + hostname + '/login/linkedin-login'
      }).subscribe((result: any) => {
        if (!result.status) {
          this.notification.presentToast('Linkedin Login Failed');
          this.router.navigate(['login']);
        }
        else {
          localStorage.setItem('email', result.email);
          localStorage.setItem('token', result.token);
          localStorage.setItem('role', result.role);
          localStorage.setItem('name', result.name);
          localStorage.setItem('phone', result.phone);
          this.loggedInSubject.next(true);

          if (redirectURL === 'foobar') {
            this.router.navigate((result.role === 'admin') || (result.role === 'workshop-manager') ? ['admin'] : ['dashboard']);
          }
          else {
            this.router.navigateByUrl(redirectURL);
          }
        }
      });
  }

  checkGmail(gmailCode, redirectURL) {
    let hostname = window.location.hostname;
    if (hostname === 'localhost') {
      hostname = hostname + ':8100';
    }
    console.log(gmailCode, window.location.protocol + '//' + hostname + '/login/gmail-login');
    this.http.post(environment.SERVER_URL + 'users/gmailLogin',
      {
        code: gmailCode,
        redirect_uri: window.location.protocol + '//' + hostname + '/login/gmail-login'
      }).subscribe((result: any) => {
        if (!result.status) {
          this.notification.presentToast('Gmail Login Failed');
          this.router.navigate(['login']);
        }
        else {
          localStorage.setItem('email', result.email);
          localStorage.setItem('token', result.token);
          localStorage.setItem('role', result.role);
          localStorage.setItem('name', result.name);
          localStorage.setItem('phone', result.phone);
          this.loggedInSubject.next(true);

          if (redirectURL === 'foobar') {
            this.router.navigate((result.role === 'admin') || (result.role === 'workshop-manager') ? ['admin'] : ['dashboard']);
          }
          else {
            window.location.href = redirectURL;
          }
        }
      });
  }

  checkGmailToken(gmailToken) {
    let hostname = window.location.hostname;
    if (hostname === 'localhost') {
      hostname = hostname + ':8100';
    }
    this.http.post(environment.SERVER_URL + 'users/gmailLogin',
      {
        id_token: gmailToken,
        redirect_uri: window.location.protocol + '//' + hostname + '/login/gmail-login'
      }).subscribe((result: any) => {
        if (!result.status) {
          this.notification.presentToast('Gmail Login Failed');
          this.router.navigate(['login']);
        }
        else {
          localStorage.setItem('email', result.email);
          localStorage.setItem('token', result.token);
          localStorage.setItem('role', result.role);
          localStorage.setItem('name', result.name);
          localStorage.setItem('phone', result.phone);
          this.loggedInSubject.next(true);

          this.router.navigate((result.role === 'admin') || (result.role === 'workshop-manager') ? ['admin'] : ['dashboard']);
        }
      });
  }

  checkFaceBook(facebookToken) {
    let hostname = window.location.hostname;
    if (hostname === 'localhost') {
      hostname = hostname + ':8100';
    }
    this.http.post(environment.SERVER_URL + 'users/facebookLogin',
      {
        authToken: facebookToken,
        redirect_uri: window.location.protocol + '//' + hostname + '/login/facebook-login'
      }).subscribe((result: any) => {
        if (!result.status) {
          this.notification.presentToast('FaceBook Login Failed');
          this.router.navigate(['login']);
        }
        else {
          localStorage.setItem('email', result.email);
          localStorage.setItem('token', result.token);
          localStorage.setItem('role', result.role);
          localStorage.setItem('name', result.name);
          localStorage.setItem('phone', result.phone);
          this.loggedInSubject.next(true);

          this.router.navigate((result.role === 'admin') || (result.role === 'workshop-manager') ? ['admin'] : ['dashboard']);
        }
      });
  }

  login(email: string, password: string) {
    return this.http.post(environment.SERVER_URL + 'users/login',
      { email, password }).pipe(map((response: any) => {
        if (response.status) {
          localStorage.setItem('email', email);
          localStorage.setItem('token', response.token);
          localStorage.setItem('role', response.role);
          localStorage.setItem('name', response.name);
          localStorage.setItem('phone', response.phone);
          this.loggedInSubject.next(true);
        }
        return response;
      }), catchError(err => {
        return throwError(err);
      })
      );
  }

  logout() {
    // localStorage.removeItem('email');
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('name');
    localStorage.removeItem('phone');

    this.loggedInSubject.next(false);
  }

  onUserChanged() {
    return this.loggedInSubject;
  }

  getUserDetailsPublic(uid) {
    return this.http.get(environment.SERVER_URL + 'users/getUserDetailsPublic/' + uid);
  }
}
