import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';

@Injectable({
providedIn: 'root'
})
export class NotificationService {
constructor(public toastController: ToastController, public platform: Platform) {
}

unlimitedToast = null;
presenting = false;
// ToDo change the CSS class of the notification bar
dangerCSS = 'bg-danger';

async presentToast(text: string) {
    // const position = this.platform.is('desktop') ? 'top' : 'bottom';
    const position = 'bottom';
    const toast = await this.toastController.create({
        position,
        message: text,
        duration: 3000
    });
    toast.present();
}
async presentUnlimitedToast(text: string) {
    // const position = this.platform.is('desktop') ? 'top' : 'bottom';
    const position = 'top';
    this.unlimitedToast = await this.toastController.create({
        position,
        message: text,
        duration: 2000000
    });
    this.unlimitedToast.present();
    this.presenting = true;
}
}
