import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomHttpParams } from 'src/app/services/http.interceptor';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserInstanceService {

  myInstances = [];
  active = [];
  inProgress = [];
  completed = [];
  executed = false;

  constructor(private http: HttpClient, private userService: UserService) {
    // if (this.userService.isLoggedIn()){
    //   this.update().subscribe();
    // }
  }

  clear() {
    this.myInstances = [];
    this.active = [];
    this.inProgress = [];
    this.completed = [];
    this.executed = false;
  }

  getMyInstances() {
    if (this.executed) {
      return new Observable((observer) => {
        observer.next(this.myInstances);
      });
    }
    else {
      return this.fetch();
    }
  }

  getActive() {
    if (this.executed) {
      return new Observable((observer) => {
        observer.next(this.active);
      });
    }
    else {
      return this.http.get(environment.SERVER_URL + 'users/course/instance', { params: new CustomHttpParams(true) }).pipe(
        map((res: any) => {
          this.getAllData(res);
          return this.active;
        })
      );
    }
  }

  getInprogress() {
    if (this.executed) {
      return new Observable((observer) => {
        observer.next(this.inProgress);
      });
    }
    else {
      return this.http.get(environment.SERVER_URL + 'users/course/instance', { params: new CustomHttpParams(true) }).pipe(
        map((res: any) => {
          this.getAllData(res);
          return this.inProgress;
        })
      );
    }
  }

  getCompleted() {
    if (this.executed) {
      return new Observable((observer) => {
        observer.next(this.completed);
      });
    }
    else {
      return this.http.get(environment.SERVER_URL + 'users/course/instance', { params: new CustomHttpParams(true) }).pipe(
        map((res: any) => {
          this.getAllData(res);
          return this.completed;
        })
      );
    }
  }

  getAllData(res) {
    this.myInstances = [];
    this.active = [];
    this.inProgress = [];
    this.completed = [];

    if (res.status) {
      for (const instance of res.instances) {
        if (instance.status === 'active') {
          this.active.push(instance);
        }
        else if (instance.status === 'in_progress') {
          this.inProgress.push(instance);
        }
        else if (instance.status === 'completed') {
          this.completed.push(instance);
        }
      }
      this.myInstances = this.active.concat(this.inProgress).concat(this.completed);
      this.executed = true;
    }
  }

  fetch() {
    console.log('Fetching user instance data...');
    return this.http.get(environment.SERVER_URL + 'users/course/instance', { params: new CustomHttpParams(true) }).pipe(
      map((res: any) => {
        this.getAllData(res);
        return this.myInstances;
      })
    );
  }

  updateFeedBackForm(instanceID, weekIndex, form) {
    // ToDo - Write this logic in server
    form.week = weekIndex;
    return this.http.post(
      environment.SERVER_URL + 'users/course/instance/',
      { feedBackForm: form, instanceID },
      { params: new CustomHttpParams(true) }
    ).pipe(
      map((res: any) => {
        this.executed = false;
        return res.status;
      })
    );
  }

  updateQuiz(instanceID, weekIndex, quiz) {
    quiz.week = weekIndex;
    console.log('before sending', quiz);
    return this.http.post(
      environment.SERVER_URL + 'users/course/instance/',
      { quiz, instanceID },
      { params: new CustomHttpParams(true) }
    ).pipe(
      map((res: any) => {
        this.executed = false;
        return res.status;
      })
    );
  }
}
