import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';

import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class MyHttpLogInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(private router: Router, private userService: UserService, private spinner: NgxSpinnerService) { }

  handleAuthError(request, err: HttpErrorResponse): Observable<any> {
    this.subtract(request);

    if (err.status === 401) {
      localStorage.setItem('email', 'null');
      localStorage.setItem('token', 'null');
      this.userService.loggedInSubject.next(false);
      this.router.navigate(['login'], { queryParams: { loginRedirect: this.router.url } });
      return of(EMPTY);
    }
    return throwError(err);
  }

  showSpinner(){
    this.spinner.show();
    setTimeout(() => {
      if(this.totalRequests > 0){
        console.log(`${this.totalRequests} requests failed`);
        this.spinner.hide();
      }
    }, 
    5000);
  }

  add(request){
    if (request.params instanceof CustomHttpParams){
      if (request.params.showLoader){
        this.totalRequests++;
        this.showSpinner();
      }
    }
  }
  subtract(request){
    if (request.params instanceof CustomHttpParams){
      if (request.params.showLoader){
        this.totalRequests--;
        if (this.totalRequests <= 0) {
          this.totalRequests = 0;
          this.spinner.hide();
        }
      }
    }
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.add(request);
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('email');
    if (!token || !email) {
      return next.handle(request).pipe(
        finalize(() => {
          this.subtract(request);
        }),
        catchError(x => this.handleAuthError(request, x)));
    }
    const headers = new HttpHeaders({ token, email });
    const customReq = request.clone({ headers });

    return next.handle(customReq).pipe(
      finalize(() => {
        this.subtract(request);
      }),
      catchError(x => this.handleAuthError(request, x)));
  }
}

export class CustomHttpParams extends HttpParams {
  constructor(public showLoader: boolean) {
   super();
  }
}