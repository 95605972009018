import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';


@Injectable({
  providedIn: 'root'
})
export class SEOService {

  defaults = {
    title: 'Autonise',
    description: 'UpSkill yourself with Autonise by getting practical knowledge & build your career in Machine Learning, Web and App Development or any field which you love!',
    keywords: 'internships, workshop, upskill, machine learning, artificial intelligence, coding, HTML, CSS, JavaScript, Ionic',
    author: 'Mayank Kumar Singh, Pragya Kumari, Shantanu Mahakale, Pooja Mundhekar',
    'og:title': 'Autonise',
    'og:image': 'https://www.autonise.com/assets/goodUI/Logo/CO.png',
    'og:video': 'https://www.autonise.com/assets/video/autoniseLogo.mp4',
    'og:type': 'website',
    'og:url': 'https://www.autonise.com',
    'og:image:secure_url': 'https://www.autonise.com/assets/goodUI/Logo/CO.png',
    'og:image:width': '200',
    'og:image:height': '200',
    'og:image:alt': 'Upskill yourself with Autonise',
    'og:video:secure_url': 'https://www.autonise.com/assets/video/autoniseLogo.mp4',
    'og:video:width': '200',
    'og:video:height': '200',
    'og:video:alt': 'Upskill yourself with Autonise'
  };

  constructor(private title: Title, private meta: Meta) { }

  public updateTitle(title: string) {
    if (title){
      this.title.setTitle(title);
    }
    else{
      this.title.setTitle(this.defaults.title);
    }
  }

  public updateMeta(name: string, content: any){
    if (content){
      if (name.startsWith('og')){
        this.meta.updateTag({property: name, content});
      }
      else{
        this.meta.updateTag({content, name});
      }
    }
    else{
      this.meta.updateTag({name, content: this.defaults[name]});
    }
  }
}
