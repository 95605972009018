import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuardGuard } from './guard/auth-guard.guard';
import {DashboardRedirectGuard} from './guard/dashboard-redirect.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    // data: {
    //   title: 'Home',
    //   description: 'Home Page',
    //   ogUrl: 'https://www.google.com'
    // }
  },
  {
    path: '',
    component: AppComponent,
    canActivate: [DashboardRedirectGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: 'aboutus',
    loadChildren: () => import('./aboutus/aboutus.module').then(m => m.AboutusPageModule)
  },
  {
    path: 'workshops',
    loadChildren: () => import('./workshops/workshops.module').then(m => m.WorkshopsPageModule)
  },
  {
    path: 'testimonials',
    loadChildren: () => import('./testimonials/testimonials.module').then(m => m.TestimonialsPageModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./tutorial/tutorial.module').then(m => m.TutorialPageModule)
  },
  {
    path: 'tutorials',
    redirectTo: 'tutorial',
    pathMatch: 'full'
  },
  {
    path: 'payments',
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then(m => m.PrivacyPageModule)
  },
  {
    path: 'admin',
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then(m => m.PrivacyPageModule)
  },
  {
    path: 'account',
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'internships',
    loadChildren: () => import('./internships/internships.module').then( m => m.InternshipsPageModule)
  },
  {
    path: 'mentor-dashboard',
    loadChildren: () => import('./mentor/mentor-dashboard/mentor-dashboard.module').then( m => m.MentorDashboardPageModule)
  },
  /* Add all your routes above this line otherwise it will be redirected to 404 */
  {
    path: 'page-not-found',
    loadChildren: () => import('./page-not-found/page-not-found.module').then( m => m.PageNotFoundPageModule)
  },
  {
    path: 'unsubscribe',
    loadChildren: () => import('./unsubscribe/unsubscribe.module').then( m => m.UnsubscribePageModule)
  },
  {
    path: 'verify-unsubscribe',
    loadChildren: () => import('./verify-unsubscribe/verify-unsubscribe.module').then( m => m.VerifyUnsubscribePageModule)
  },
  {
    path: 'verifyCertificate',
    loadChildren: () => import('./verify-certificate/verify-certificate.module').then( m => m.VerifyCertificatePageModule)
  },
  {
    path: 'webinar',
    loadChildren: () => import('./webinar/webinar.module').then( m => m.WebinarPageModule)
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
    pathMatch: 'full'
  },

];

// preloadingStrategy: PreloadAllModules,
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'disabled'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
