import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/services/notification.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  internships = [];
  workshops = [];
  both = [];
  executed = false;

  constructor(private http: HttpClient, private notification: NotificationService) {
  }

  clear() {
    this.internships = [];
    this.workshops = [];
    this.both = [];
    this.executed = false;
  }

  getAllData(result) {
    this.internships = [];
    this.workshops = [];
    this.both = [];

    if (!result.status) {
      this.notification.presentToast('Some Error in fetching the internships. Please contact the administration at info-autonise@autonise.com');
      return false;
    }
    else {
      for (const catalog of result.catalogs) {
        if (catalog.courseType === 'Internship') {
          this.internships.push(catalog);
          this.both.push(catalog);
        }
        else if (catalog.courseType === 'Workshop') {
          this.workshops.push(catalog);
          this.both.push(catalog);
        }
        else {
        }
      }
      this.executed = true;
      return true;
    }
  }

  get() {
    if (this.executed) {
      return new Observable((observer) => {
        observer.next(this.both);
      });
    }
    return this.http.get(environment.SERVER_URL + 'course/catalog').pipe(map((result: any) => {
      this.getAllData(result);
      return this.both;
    }));
  }

  getById(catalogId) {
    return this.get().pipe(map((both: any) => {
      return both.find(catalog => catalog._id === catalogId);
    }));
  }

  fetch() {
    console.log('Fetching catalog..');
    return this.http.get(environment.SERVER_URL + 'course/catalog').pipe(map((result: any) => {
      this.getAllData(result);
      return result;
    }));
  }

  getWorkshops() {
    if (this.executed) {
      return new Observable((observer) => {
        observer.next(this.workshops);
      });
    }
    return this.http.get(environment.SERVER_URL + 'course/catalog').pipe(map((result: any) => {
      this.getAllData(result);
      return this.workshops;
    }));
  }

  getInternships() {
    if (this.executed) {
      return new Observable((observer) => {
        observer.next(this.internships);
      });
    }
    return this.http.get(environment.SERVER_URL + 'course/catalog').pipe(map((result: any) => {
      this.getAllData(result);
      return this.internships;
    }));
  }
}
