import { CustomHttpParams } from './../../http.interceptor';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/services/notification.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstanceService {

  all = {};
  active = {};
  inProgress = {};
  inactive = {};
  closed = {};
  executed = false;

  constructor(private http: HttpClient, private notification: NotificationService) {
  }

  fetch() {
    console.log('Fetching instance data...');
    return this.http.get(environment.SERVER_URL + 'course/instance', { params: new CustomHttpParams(true) }).pipe(
      map((res: any) => {
        this.mapInstances(res);
        return res;
      })
    );
  }

  clear() {
    this.all = {};
    this.active = {};
    this.inProgress = {};
    this.inactive = {};
    this.closed = {};
    this.executed = false;
  }

  mapInstances(result) {
    if (!result.status) {
      this.executed = true;
      this.notification.presentToast(
        'Some Error in fetching the instances. Please contact the administration at info-autonise@autonise.com');
      return false;
    }
    this.all = {};
    this.active = {};
    this.inProgress = {};
    this.inactive = {};
    this.closed = {};

    for (const instance of result.instances) {
      this.all[instance._id] = instance;
      if (instance.status === 'active') {
        if (Object.keys(this.active).indexOf(instance.catalogId) > -1) {
          this.active[instance.catalogId].push(instance);
        }
        else {
          this.active[instance.catalogId] = [instance];
        }
      }
      else if (instance.status === 'inactive') {
        if (Object.keys(this.inactive).indexOf(instance.catalogId) > -1) {
          this.inactive[instance.catalogId].push(instance);
        }
        else {
          this.inactive[instance.catalogId] = [instance];
        }
      }
      else if (instance.status === 'closed') {
        if (Object.keys(this.closed).indexOf(instance.catalogId) > -1) {
          this.closed[instance.catalogId].push(instance);
        }
        else {
          this.closed[instance.catalogId] = [instance];
        }
      }
      else if (instance.status === 'inProgress') {
        if (Object.keys(this.inProgress).indexOf(instance.catalogId) > -1) {
          this.inProgress[instance.catalogId].push(instance);
        }
        else {
          this.inProgress[instance.catalogId] = [instance];
        }
      }
    }
    this.executed = true;
    return true;
  }

  getById(id) {
    if (this.executed) {
      return new Observable((observer) => {
        observer.next(this.all[id]);
      });
    }
    return this.fetch().pipe(map(() => this.all[id]));
  }

  getActiveInstances() {
    if (this.executed) {
      return new Observable((observer) => {
        observer.next(this.active);
      });
    }
    return this.http.get(environment.SERVER_URL + 'course/instance', { params: new CustomHttpParams(true) }).pipe(
      map((result: any) => {
        this.mapInstances(result);
        return this.active;
      })
    );
  }

  getInactiveInstances() {

    if (this.executed) {
      return new Observable((observer) => {
        observer.next(this.inactive);
      });
    }
    return this.http.get(environment.SERVER_URL + 'course/instance').pipe(
      map((result: any) => {
        this.mapInstances(result);
        return this.inactive;
      })
    );
  }

  getInProgressInstances() {

    if (this.executed) {
      return new Observable((observer) => {
        observer.next(this.inProgress);
      });
    }
    return this.http.get(environment.SERVER_URL + 'course/instance').pipe(
      map((result: any) => {
        this.mapInstances(result);
        return this.inProgress;
      })
    );
  }

  getClosedInstances() {

    if (this.executed) {
      return new Observable((observer) => {
        observer.next(this.closed);
      });
    }
    return this.http.get(environment.SERVER_URL + 'course/instance').pipe(
      map((result: any) => {
        this.mapInstances(result);
        return this.closed;
      })
    );
  }
}
