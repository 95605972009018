import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {

  constructor(private route: Router, private userService: UserService) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.userService.isLoggedIn()) {
      if (state.url.startsWith('/admin')) {
        const role = localStorage.getItem('role');
        return role === 'admin' || role === 'workshop-manager' || role === 'mentor';
      } else {
        return true;
      }
    }
    else {
      this.route.navigate(['login'], { queryParams: { loginRedirect: state.url } });
      return false;
    }
  }
}
