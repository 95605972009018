import { Injectable } from '@angular/core';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {

  public ws: ReconnectingWebSocket;
  constructor() {
  }

  connect(videoID, workshopName, roomID) {
    const information = localStorage.getItem('email') + '/' + videoID + '/' + workshopName + '/' + roomID + '/';
    this.ws = new ReconnectingWebSocket(environment.SOCKET_URL + '/ws/attendence/' + information);
  }

  markAttendance(start: number, end: number) {
    this.ws.send(JSON.stringify({ start, end }));
  }

  disconnect() {
    if (this.ws !== undefined){
      this.ws.close();
    }
  }
}
