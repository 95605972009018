import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CheckPlatformService {
  ua = navigator.userAgent;

  constructor() { }

  check(){
    return !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(this.ua);
  }
}
