import { Component, OnInit, ViewChild } from '@angular/core';

import { Platform, MenuController, IonContent } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UserService } from './services/user.service';
import { ActiveUserService } from './services/active-user.service';
import { CheckPlatformService } from './services/check-platform.service';
import { NavigationEnd, Router, Event, ActivatedRoute } from '@angular/router';
import { SEOService } from 'src/app/services/seo.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  public selectedIndex = 0;
  public isDesktop = false;
  public isLoggedIn = false;

  @ViewChild(IonContent) content: IonContent;

  internships = ['E Commerce Website', 'Quick Draw Reognizer', 'Chat Bot Development'];

  dropdown = false;
  admin = false;

  public appPages = [
    {
      title: 'Login',
      url: 'login',
      icon: 'person',
      type: 'loggedOut'
    },
    {
      title: 'Home',
      url: 'home',
      icon: 'home',
      type: 'always'
    },
    {
      title: 'About Us',
      url: 'aboutus',
      icon: 'people',
      type: 'always'
    },
    {
      title: 'Internships',
      url: 'internships',
      icon: 'briefcase',
      type: 'always',
    },
    // {
    //   title: 'Workshops',
    //   url: 'workshops',
    //   icon: 'tv',
    //   type: 'always'
    // },
    {
      title: 'Earn',
      url: 'account',
      queryParams: { view: 'referral'},
      icon: 'cash',
      type: 'loggedIn'
    },
    {
      title: 'Tutorial',
      url: 'tutorial',
      icon: 'albums',
      type: 'always'
    },
    {
      title: 'Dashboard',
      url: 'dashboard',
      icon: 'browsers',
      type: 'loggedIn'
    },
    {
      title: 'Account',
      url: 'account',
      icon: 'browsers',
      type: 'loggedIn'
    },
    {
      title: 'Admin',
      url: '/admin/manage-courses',
      icon: 'person',
      type: 'admin'
    },
    {
      title: 'Logout',
      url: '/login/logout',
      icon: 'log-out',
      type: 'loggedIn'
    }
  ];
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private userService: UserService,
    public menu: MenuController,
    private checkPlatform: CheckPlatformService,
    // private logService: LoggerService,
    private router: Router,
    public seoService: SEOService,
    private activatedRoute: ActivatedRoute,
    private activeUserService: ActiveUserService,
    private http: HttpClient
  ) {
    this.initializeApp();
    this.activeUserService.connect();
  }

  close() {
    this.menu.close();
  }

  disable() {
    this.menu.enable(false);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.menu.enable(false);
    });
  }

  ngOnInit() {
    this.isDesktop = this.checkPlatform.check();
    const path = window.location.pathname.split('/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.url.toLowerCase() === path.toLowerCase());
    }
    this.isLoggedIn = this.userService.isLoggedIn();
    const role = localStorage.getItem('role');
    this.admin = role === 'admin' || role === 'workshop-manager' || role === 'mentor';
    this.userService.onUserChanged().subscribe((val: boolean) => {
      this.activeUserService.reconnect();
      this.isLoggedIn = val;
      const roleInner = localStorage.getItem('role');
      this.admin = roleInner === 'admin' || roleInner === 'workshop-manager' || roleInner === 'mentor';
    });
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route: any) => {
        while (route.firstChild){
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
     )
     .subscribe((event: any) => {
        this.seoService.updateTitle(event.title);
        this.seoService.updateMeta('description', event.description);
        this.seoService.updateMeta('author', event.author);
        this.seoService.updateMeta('keywords', event.keywords);

        this.seoService.updateMeta('og:title', event['og:title']);
        this.seoService.updateMeta('og:image', event['og:image']);
        this.seoService.updateMeta('og:video', event['og:video']);
        this.seoService.updateMeta('og:type', event['og:type']);
        this.seoService.updateMeta('og:url', event['og:url']);
        this.seoService.updateMeta('og:description', event['og:description']);

        this.seoService.updateMeta('og:image:secure_url', event['og:image:secure_url']);
        this.seoService.updateMeta('og:image:width', event['og:image:width']);
        this.seoService.updateMeta('og:image:height', event['og:image:height']);
        this.seoService.updateMeta('og:image:alt', event['og:image:alt']);

        this.seoService.updateMeta('og:video:secure_url', event['og:video:secure_url']);
        this.seoService.updateMeta('og:video:width', event['og:video:width']);
        this.seoService.updateMeta('og:video:height', event['og:video:height']);
        this.seoService.updateMeta('og:video:alt', event['og:video:alt']);

    });
    this.router.events.subscribe((event: Event) => {
      // if (event instanceof NavigationStart) {
      //     // Show loading indicator
      //     // console.log('NavigationStart');
      // }
      if (event instanceof NavigationEnd) {
          // console.log('NavigationEnd');
          // To get button Id of button clicked by user on home Page
          const xyz = this.activatedRoute.snapshot.queryParamMap.get('xyz');
          if (xyz !== undefined && xyz !== null){
            this.http.get(environment.SERVER_URL + 'users/logButtonID?button_id=' + xyz).subscribe((res: any) => {
            });
          }
      }
      // if (event instanceof NavigationError) {
      //     // Hide loading indicator
      //     // console.log('NavigationError');

      //     // Present error to user
      //     // console.log(event.error);
      // }
    });
  }

  Dropdown(){
    this.dropdown = !this.dropdown;
  }
}
