import { Injectable } from '@angular/core';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { environment } from 'src/environments/environment';
import { InstanceService } from './course/instance/instance.service';
import { UserInstanceService } from './users/course/instance/instance.service';
import { CatalogService } from './course/catalog/catalog.service';

@Injectable({
  providedIn: 'root'
})
export class ActiveUserService {
  public ws: ReconnectingWebSocket;

  services = {};

  constructor(
    private instanceService: InstanceService, private userInstanceService: UserInstanceService,
    private catalogService: CatalogService) {
    // tslint:disable: no-string-literal
    this.services['catalogService'] = catalogService;
    this.services['instanceService'] = instanceService;
    this.services['userInstanceService'] = userInstanceService;
  }

  connect() {
    this.ws = new ReconnectingWebSocket(environment.SOCKET_URL + '/ws/active-users/' + localStorage.getItem('email') + '/');
    this.listen();
  }

  disconnect() {
    this.ws.close();
  }

  reconnect() {
    this.ws.close();
    this.ws = new ReconnectingWebSocket(environment.SOCKET_URL + '/ws/active-users/' + localStorage.getItem('email') + '/');
    this.listen();
  }

  listen() {
    this.ws.onmessage = (e) => {
      const data = JSON.parse(e.data);

      if (data.action === 'reload') {
        if (localStorage.getItem('email') !== 'info-autonise@autonise.com') {
          window.location.href = window.location.href;
        }
      }
      else if (data.action === 'reload-services') {
        console.log(data.services);
        for (const service of data.services) {
          this.services[service].fetch();
        }
      }
    };
  }
}
